
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

::v-deep {
    .el-dialog {
        background-color: #FBFCFE;
        min-width: calc(250px + 15vw);
    }
    
    .el-dialog__header {
        padding: 32px 32px 0;
    }

    .el-dialog__body {
        padding: 0 32px 32px;
    }

    .el-dialog__footer {
        padding-left: 32px;
        padding-right: 32px;
    }

    .input-url {
        .el-input--suffix .el-input__inner {
            padding-right: 12rem;
            text-overflow: ellipsis;
        }
    }

    [class*=" el-icon-"], [class^=el-icon-] {
        color: #FE2615;
    }
}
