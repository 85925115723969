
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.logo {
    height: 100%;
}
.metrics {
    margin-top: -1.5em;
    @apply w-full flex flex-wrap px-0 py-4 laptop:px-8 laptop:pb-4;

    .metrics-item {
        @apply w-full flex laptop:w-1/4 flex-col;
    }

    .metrics-item_header {
        @apply w-full text-xs text-gray-text;
    }
}

::v-deep {
    .header {
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: start;

            .filters {
                justify-content: flex-start;
            }
        }
    }

  .card {
    padding: 0 !important;
      height: auto;
  }
}

.offer-common {
  margin-bottom: 0 !important;
  @media screen and (min-width: 768px) {
    border-radius:  0.25rem 0 0  0.25rem !important;
  }
}
