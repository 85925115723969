
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.tooltip-content-list {
    margin: -0.35rem -0.75rem;
    padding: 0.35rem 0.75rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
}

ul {
    margin: 0 !important;
    list-style: none !important;

    .tippy {
      display: flex;
    }

    li {
        padding: 0;

        div {
            display: flex;
            text-align: left;
            align-items: center;
            justify-content: left;

            .flag-icon {
                flex: none;
                margin-right: 0.4em;
            }
        }
    }
}

ul li div .flag-icon[data-v-2ccd4fa0] {
    width: 1.75rem !important;
    height: 1.25rem !important;

    box-shadow: 0 0 0 1px #dee3ec;
    box-sizing: border-box;
    border-radius: 4px;
    background-size: cover;
}

.el-popper {
    width: auto;
    padding: 0.35rem 0.75rem;
    border-radius: 10px;
    font-size: 0.85rem;
    font-weight: 400;
    box-shadow: 0px 1px 2px 1px rgb(0 1 0 / 20%);
    z-index: 38;
    white-space: nowrap;
    position: absolute;
}

.el-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 2em;
    margin-left: .5rem;
    padding-left: 0.75em;
    padding-right: 0.75em;
    color: #ffba40;
    font-size: 0.75rem;
    border-radius: 5px;
    background-color: whitesmoke;
    line-height: 1.5;
    white-space: nowrap;
    border: none !important;
    cursor: pointer;
}
