
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

th {
    padding: 0.8em 0.5em;

    & > div {
        display: flex;
        white-space: nowrap;
        justify-content: flex-end;

        ::v-deep {
            i {
                margin-left: 0.5em;
            }
        }
    }
}

.tippy {
    vertical-align: middle;
}

.image {
    &.is-38x38 {
        $size: 38px;

        width: $size;
        height: $size;
        max-width: $size;
        min-width: $size;
    }
}
