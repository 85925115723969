:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

:export{
  mainFont:"Rubik";
  bodyFonts:"Rubik",BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
  monospaced:"Fira Mono"
}

:export{
  sizeNormal:.875rem
}

:export{
  light:#b5b5b5;
  lightInvert:#fff;
  lightButton:rgba(181,181,181,.15);
  lightButtonInvert:#828282;
  info:#1a5cff;
  infoInvert:#fff;
  infoButton:rgba(26,92,255,.15);
  infoButtonInvert:#1a5cff;
  success:#46c93a;
  successInvert:#fff;
  successButton:rgba(70,201,58,.15);
  successButtonInvert:#46c93a;
  warning:#ffba00;
  warningInvert:rgba(0,0,0,.7);
  warningButton:rgba(255,186,0,.15);
  warningButtonInvert:#ffba00;
  danger:#ff4757;
  dangerInvert:#fff;
  dangerButton:rgba(255,71,87,.15);
  dangerButtonInvert:#ff4757;
  primary:#ffba40;
  primaryInvert:#fff;
  primaryButton:rgba(255,186,64,.15);
  primaryButtonInvert:#ffba40
}

.f-col{
  padding:.5rem
}

.logo{
  height:100%
}

.metrics{
  margin-top:-1.5em;
  display:flex;
  width:100%;
  flex-wrap:wrap;
  padding-left:0px;
  padding-right:0px;
  padding-top:1rem;
  padding-bottom:1rem
}

@media (min-width: 769px){
  .metrics{
    padding-left:2rem;
    padding-right:2rem;
    padding-bottom:1rem
  }
}

.metrics .metrics-item{
  display:flex;
  width:100%;
  flex-direction:column
}

@media (min-width: 769px){
  .metrics .metrics-item{
    width:25%
  }
}

.metrics .metrics-item_header{
  width:100%;
  font-size:0.75rem;
  line-height:1rem;
  --tw-text-opacity:1;
  color:rgba(154, 163, 180, var(--tw-text-opacity))
}

@media screen and (max-width: 768px){
  ::v-deep .header{
    display:flex;
    flex-direction:column;
    align-items:start
  }

  ::v-deep .header .filters{
    justify-content:flex-start
  }
}

::v-deep .card{
  padding:0 !important;
  height:auto
}

.offer-common{
  margin-bottom:0 !important
}

@media screen and (min-width: 768px){
  .offer-common{
    border-radius:.25rem 0 0 .25rem !important
  }
}