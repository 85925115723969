
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.countries {
    @apply w-full flex flex-wrap overflow-y-auto;
    .countries_item {
        @apply w-full bg-white mt-2 bg-white overflow-hidden f-col shadow-md;
    }
}
