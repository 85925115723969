
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.rules {
    @apply w-full flex flex-wrap bg-white shadow-md;

    .rules-item {
        @apply w-full flex p-6 tablet:w-1/2 laptop:w-1/3 desktop:w-1/4 flex-col overflow-hidden;
        border-bottom: 1px solid #eeeeee;
    }

    .rules-item_header {
        @apply w-full text-lg mb-2 font-semibold
    }

    .rules-item_body {
        @apply text-sm flex items-center
    }

    .rules-item_banned {
        color: #FE2615
    }
}
